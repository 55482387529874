// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-author-page-index-js": () => import("./../../../src/templates/AuthorPage/index.js" /* webpackChunkName: "component---src-templates-author-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("./../../../src/templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */)
}

